<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />
        </b-link>

        <b-card-title class="mb-1">
          KASS YAZILIM'a Hoş geldiniz! 👋
        </b-card-title>
        <b-card-text class="mb-2">
          Lütfen hesabınıza giriş yapın ve ürünlerinizi yönetin
        </b-card-text>

        <!-- form -->
        <validation-observer ref="loginForm">
          <b-form class="auth-login-form mt-2" @submit.prevent="login">
            <!-- email -->
            <b-form-group label-for="email" label="E-Posta">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="ornek@kassyazilim.com"
                  autofocus
                />
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <label for="password">{{ $t('Parola') }}</label>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                    placeholder="Parola"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
              </validation-provider>
            </b-form-group>
            <b-row>
              <b-col>
                <b-form-group>
                  <b-form-checkbox
                    id="remember-me"
                    v-model="rememberMe"
                    name="checkbox-1"
                  >
                    {{ $t('Beni Hatırla') }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <b-link :to="{ name: 'forgot-password' }">
                      <small>{{ $t('Parolanızı mı unuttunuz?') }}</small>
                    </b-link>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <div class="m-2">
              <vue-recaptcha
                ref="recaptcha"
                @verify="onVerify"
                @expired="onExpired"
                :sitekey="sitekey"
              />
            </div>
            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="$store.getters.getLoader"
            >
              <b-spinner v-if="$store.getters.getLoader" small />
              Giriş
            </b-button>
          </b-form>
        </validation-observer>
        <b-card-text class="text-center mt-2">
          © Tüm Hakları Saklıdır.<br>KASS YAZILIM VE OTOMASYON SİS. SAN. TİC.
          LTD. ŞTİ.
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import useJwt from '@/auth/jwt/useJwt'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueRecaptcha from 'vue-recaptcha';
// Alert
import AlertService from '@/common/alert.service'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueRecaptcha,
    VuexyLogo,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      sitekey: useJwt.recaptchaSiteKey,
      userEmail: '',
      password: '',
      recaptchaToken: '',
      rememberMe: false,
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          if (this.recaptchaToken) {
            this.$store.commit('setLoader', true)
            useJwt.login({
              userName: this.userEmail,
              password: this.password,
              rememberMe: this.rememberMe,
              recaptchaToken: this.recaptchaToken,
            })
              .then(response => {
                if (response.statusCode === 200) {
                  const userData = response.result
                  useJwt.setToken(response.result.token)
                  useJwt.setRefreshToken(response.result.token)
                  useJwt.setUserData(userData)

                  this.$router.push(getHomeRouteForLoggedInUser(userData.userRole))
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `${this.$t('Hoş Geldiniz')}  ${userData.fullName}`,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          text: this.$t('Sisteme başarılı bir şekilde giriş yaptınız'),
                        },
                      })
                    })
                } else {
                  AlertService.warning(this, response.message);
                  this.recaptchaToken = '';
                  this.$refs.recaptcha.reset();
                }
                this.$store.commit('setLoader', false)
              }).catch(err => {
                this.$store.commit('setLoader', false);
                AlertService.error(this, err);
                this.recaptchaToken = '';
                this.$refs.recaptcha.reset();
              });
          } else {
            AlertService.warning(this, 'Doğrulamayı tamamlayınız');
          }
        }
      })
    },
    onVerify(response) {
      this.recaptchaToken = response;
    },

    onExpired() {
      this.recaptchaToken = '';
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
